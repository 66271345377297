import React from "react";
import whiteCar from "../../Assets/sss.png";
import car2 from "../../Assets/vvvv.png";
import car3 from "../../Assets/car6.png";

const carList = [
  {
    name: "Mercedes S-Class",
    // price: 140,
    image: "https://www.motortrend.com/uploads/sites/10/2016/11/2017-mercedes-benz-s-class-plug-in-hybrid-sedan-angular-front.png",
    // image: car2,
    aosDelay: "500",
  },
  {
    name: "Cadillac CT4-V",
    // price: 140,
    image: "https://www.cars.com/i/large/in/v2/stock_photos/77276230-9da7-4b5e-81c6-56e0b95ce4ea/8cb5e94a-1097-4cde-abe8-b13894a8adae.png",
    // image: car2,
    aosDelay: "500",
  },
  {
    name: "Executive Sprinter",
    // price: 100,
    image: "https://www.metroblacksedans.com/wp-content/uploads/2016/12/suburban-front.jpg",
    // image: whiteCar,
    aosDelay: "0",
  },
  {
    name: "Rolls Royce",
    // price: 100,
    image: "https://totalpng.com//public/uploads/preview/rolls-royce-png-transparent-11667030544xxdkotdid6.png",
    // image: car3,
    aosDelay: "1000",
  },
  {
    name: "",
    // price: 100,
    image: "https://firebasestorage.googleapis.com/v0/b/test-f840f.appspot.com/o/thumbnail_Screenshot_20220701-083840_Chrome.jpg?alt=media&token=eae64463-adbe-4b00-9ce4-6583a240592b",
    // image: car3,
    aosDelay: "1000",
  },
  {
    name: "",
    // price: 100,
    image: "https://firebasestorage.googleapis.com/v0/b/test-f840f.appspot.com/o/40-Passenger-party-bus-orlando.jpg?alt=media&token=c7c69a9a-9aa2-4ed3-95d2-06a668b753a2",
    // image: car3,
    aosDelay: "1000",
  },
  {
    name: "",
    // price: 100,
    image: "https://firebasestorage.googleapis.com/v0/b/test-f840f.appspot.com/o/8-Pass-Mercede-Benz-Road-Stallions-1.jpg?alt=media&token=ce7442a7-bd5d-4e01-b163-452f5a468a1a",
    // image: car3,
    aosDelay: "1000",
  },
  {
    name: "",
    // price: 100,
    image: "https://firebasestorage.googleapis.com/v0/b/test-f840f.appspot.com/o/Copy-of-Untitled.png?alt=media&token=e166e420-040f-4d08-bf1a-7d5b2279a88b",
    // image: car3,
    aosDelay: "1000",
  },
  {
    name: "",
    // price: 100,
    image: "https://www.empirecls.com/wp-content/uploads/2018/08/motorcoach-rental-NYC.jpg",
    // image: car3,
    aosDelay: "1000",
  },
  {
    name: "",
    // price: 100,
    image: "https://firebasestorage.googleapis.com/v0/b/test-f840f.appspot.com/o/download.jpg?alt=media&token=f994d402-1cf4-47c3-85b6-3f1d928885aa",
    // image: car3,
    aosDelay: "1000",
  },
  // {
  //   name: "",
  //   // price: 100,
  //   image: "https://www.lastingimpressions1.com/wp-content/uploads/2014/03/Bl_Icon_Composite_8001.jpg",
  //   // image: car3,
  //   aosDelay: "1000",
  // },
  {
    name: "",
    // price: 100,
    image: "https://www.fivestarlimonv.com/wp-content/uploads/Hummer-Limo.jpg",
    // image: car3,
    aosDelay: "1000",
  },
];

const CarList = () => {
  return (
    <div id="ca" className="pb-24">
      <div className="container">
        {/* Heading */}
        <h1
          data-aos="fade-up"
          className="text-3xl sm:text-4xl font-bold font-Ubuntu mb-3"
        >
          Our Car Collections
        </h1>
        <p data-aos="fade-up" aos-delay="400" className="text-sm pb-10">
          We rent out Quality cars checkout our collection below
        </p>
        {/* Car listing */}
        {/* <div>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4">
            {carList.slice(0, 4).map((data) => (
              <div
                data-aos="fade-up"
                data-aos-delay={data.aosDelay}
                className="space-y-2 bg-gradient-to-tr from-gray-400  to-gray-100 border-0 border-gray-300 hover:border-primary p-3 text-center rounded-xl relative group"
              >
                <div className="w-full h-[120px]">
                  <img
                    src={data.image}
                    alt=""
                    className="md:w-full w-96 h-[200px] object-contain sm:translate-x-8 group-hover:sm:translate-x-16 duration-700"
                  />
                </div>
                <div className="space-y-2 pt-10">
                  <h1 className="text-white font-bold text-2xl">{data.name}</h1>
                  <div className="flex justify-between items-center text-xl font-semibold">
                  </div>
                </div>
                <p className="text-xl font-semibold absolute top-0 left-3"></p>
              </div>
            ))}
          </div>
        </div> */}


        <div className="mt-0">
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4">
            {carList.map((data) => (
              <div
                data-aos="fade-up"
                data-aos-delay={data.aosDelay}
                className="space-y-2  border-0 border-gray-300 hover:border-primary p-3 text-center rounded-xl relative group"
              >
                <div className="w-full h-[120px]">
                  <img
                    src={data.image}
                    alt=""
                    style={{borderRadius: 30}}
                    className="md:w-full w-96 object-contain lg:mt-0 mt-10 sm:translate-x-8 group-hover:sm:translate-x-16 duration-700"
                  />
                </div>
                <div className="space-y-2 pt-10">
                  {/* <h1 className="text-white font-bold text-2xl">{data.name}</h1> */}
                  <div className="flex justify-between items-center text-xl font-semibold">
                    {/* <p>${data.price}/Day</p> */}
                    {/* <a href="#">Details</a> */}
                  </div>
                </div>
                <p className="text-xl font-semibold absolute top-0 left-3"></p>
              </div>
            ))}
          </div>
        </div>
        {/* End of car listing */}
        <div className="grid place-items-center mt-8">
          <a href="https://sp.limoconnect247.net/webbookings/#booking/91cdf4bc186b4d91b78a927fbf382d96" data-aos="fade-up" className="button-outline cursor-pointer text-white bg-primary py-3 text-center w-60 rounded-lg">
          {/* <a href="https://book.mylimobiz.com/v4/blessedlimotrans" data-aos="fade-up" className="button-outline cursor-pointer text-white bg-primary py-3 text-center w-60 rounded-lg"> */}
          Get Started
          </a>
        </div>
      </div>
    </div>
  );
};

export default CarList;
